import request from '../until/request.js'

// 收藏
export const addCollect = data => {
  return request.post('/admin/addCollect', data)
}

// 删除收藏
export const delCollect = data => {
  return request.post('/admin/delCollect', data)
}
// 获取用户发的收藏
export const getUserCollect = data => {
  return request.get('/admin/getUserCollect')
}







