<template>

<div class="Collect" width="80%">
<router-view v-show="showAddCollect" @showCollectInfo="showCollect"></router-view>
    <div class="addCollect" v-show="!showAddCollect">
        <el-tabs class="el-tabs" v-model="activeName" @tab-click="handleClick">
            <!-- 全部列表 -->
    <el-tab-pane label="全部" name="first">
    <div v-show="isShow4" >
            <div class="collectInfo"

              v-for="(item,index) in collects" :key="index">
            <div class="top">
            <div class="title">
                <h3>{{item.title}}</h3>
            </div>
            <div class="data">
                <p><span>发布时间:</span>{{item.pubdata}}</p>
            </div>
        </div>
        <!-- <div class="content">
            <p>{{item.content}}</p>
        </div> -->
        <!-- <div class="ql-snow">
          <div class="ql-editor">
            <div class="content">
              <div v-html="item.content"></div>
          </div>
          </div>
      </div> -->
        <div class="info">
            <div class="views">
                <ul>
                     <li><i class="view-icon"></i><span>{{item.view}}</span></li>
                    <li><i class="like-icon"></i><span>{{item.likes}}</span></li>

                    <li><i class="comment-icon"></i><span>{{item.commentNum}}</span></li>
                </ul>
            </div>
            <div class="edit">
                <ul>
                  <li @click.stop="$router.push('/question/'+item.id,{params:{queId:item.id}})">查看</li>
                    <li @click.stop="delCollect({que_id:item.id,user_id:item.aut_id})"><span><i class="el-icon-delete"></i></span>删除</li>

                </ul>

            </div>
        </div>
        </div>
    </div>

    <el-empty class="el-empty" v-show="!isShow4" :image-size="200"></el-empty>
    </el-tab-pane>

  </el-tabs>
    </div>

</div>
</template>

<script>
import { getUserCollect,delCollect } from '../../API/Collect.js'
export default {
  name: 'userCollect',
  data () {
    return {
      // 全部列表
      collects: [],
      // 已发布问题列表
      pubArr: [],
      // 下架的问题列表
      downArr: [],
      // 回收站
      delArr: [],
      showAddCollect: false,
      isShow1: true,
      isShow2: true,
      isShow3: true,
      isShow4: true,
      activeName: 'first'
    }
  },
  methods: {
    //   tab栏
    handleClick (tab, event) {

    },
    showDown () {

    },
    // 发布文章
    addCollect () {
      this.showAddCollect = true
    },
    showCollect (id) {
      this.showAddCollect = false
    },
    
    // 获取所有收藏
    async getCollects () {

      const { data: res } = await getUserCollect()

      if (res.status === 0) {
        console.log(res.results)
        res.results.map((item, index, arr) => {
          // 已发布列表(包含既未下架也未删除)
          if (item.isdelete === 0 && item.isdown === 0) {
            this.pubArr = [...this.pubArr, item]
          }
          //   全部列表(不包括删除的)
          if (item.isdelete === 0) {
            this.collects = [...this.collects, item]
          }
          //   下架列表
          if (item.isdown === 1 && item.isdelete === 0) {
            this.downArr = [...this.downArr, item]
          }
          //   删除列表（包括下架或删除的）
          if (item.isdelete === 1) {
            this.delArr = [...this.delArr, item]
          }
        })
      }

      if (this.pubArr.length !== 0) {
        this.isShow1 = true
      } else {
        this.isShow1 = false
      }
      if (this.downArr.length !== 0) {
        this.isShow2 = true
      } else {
        this.isShow2 = false
      }
      if (this.delArr.length !== 0) {
        this.isShow3 = true
      } else {
        this.isShow3 = false
      }
      if (this.collects.length !== 0) {
        this.isShow4 = true
      } else {
        this.isShow4 = false
      }
    },
        // 真正删除
        async delCollect (form) {
          try {
            // 提示用户确认
            this.$confirm('此操作将永久删除该收藏, 是否继续?', '提示', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning'
            }).then(async () => {
              try {
                console.log(form)
                const { data: res } = await delCollect(form) // 确保delCollect是正确导入的
                if (res.status === 0) {
                  this.$message({
                    type: 'success',
                    message: '删除成功!'
                  })
                  window.location.reload(true);
                } else {
                  this.$message({
                    type: 'error',
                    message: '删除失败'
                  })
                }
              } catch (error) {
                console.error('删除操作时发生错误:', error)
                this.$message({
                  type: 'error',
                  message: '删除时发生错误，请重试'
                })
              }
            }).catch(() => {
              this.$message({
                type: 'info',
                message: '已取消删除'
              })
            })
          } catch (error) {
            console.error('确认对话框处理时发生错误:', error)
          }
}

  },
  created () {
    this.getCollects()
  }
}
</script>

<style lang='less' scoped>
*{
    margin: 0;
    padding: 0;

}
.Collect{
  margin: .5rem auto;
    width:70%;
li{
    list-style: none;
}
.addCollect{
  width:100%;
    overflow-x: hidden;
        background-color: #fff;
        position: relative;
        .el-tabs{
            padding: 10px 15px;
            width: 95%;
        }
}
.collectInfo{
    width: 100%;
    overflow-x: hidden;
    background-color:#fff ;
    border-bottom: 1px solid #dbdbdf;

    .top{
        display: flex;
        justify-content: space-between;
        padding: 20px 5px;
        .title{
            display: flex;
            align-items: center;
            p{
                margin: 0 20px;
                color: #FF3535;
                font-size: 24px;
            }
            h3{
                span{
                    color: #d47171;
                }
            }
        }
        .data{
            min-width: 142px;
            text-align: right;
            height: 24px;
            line-height: 24px;
        }
    }
    .content{
         padding: 15px 5px;
         overflow: hidden;
         height: 80px;
         width:95%;
        p{
          // width: 430px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          line-height: 30px;
          -webkit-box-orient: vertical;
          text-indent: 12px;
         }
    }
    .info{
        display: flex;
        padding: 15px 20px;
        justify-content: space-between;
        .views{
            ul{
                display: flex;
                 li{
                    margin: 0 5px;
                     i{
                        display: inline-block;
                        width: 16px;
                        height: 16px;
                        vertical-align: bottom;
                        margin-right: 8px;
                        background-size: 16px!important;
                    }
                    .view-icon{
                        background: url('../../../public/images/浏览.png') no-repeat;
                    }
                    .like-icon{
                        background: url('../../../public/images/点赞.png');
                    }
                    .comment-icon{
                        background: url('../../../public/images/消息.png');
                    }
                }
            }
        }
        .edit{
            ul{
                display: flex;
                li{
                    margin: 0 5px;
                    cursor: pointer;
                }
               li:hover{

                   color: #ff3535;
               }
            }
        }
    }
}

 .el-empty{
            background-color: #fff;
            padding: 8px 0;
    }
  }
</style>
